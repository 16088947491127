import * as React from "react";
import Accordion from "react-bootstrap/Accordion";

const onlineCoursesFAQ = () => {
  return (
    <>
      <h1>Frequently Asked Questions</h1>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Q: What is an Online Course?</Accordion.Header>
          <Accordion.Body>
            <p>
              Meridian’s online courses are conducted via the University's own
              learning platform.
            </p>
            <p>
              Our online courses promote community learning, through strong
              interactive engagement with fellow students and faculty as well as
              live video sessions with faculty.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Q: What technology do I need to take an online course?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              You will need an email account, a high-speed internet connection,
              and access to a computer, iOS or Android device.
            </p>
            <p>
              If you are planning to attend the course video calls live, you
              will need a webcam and microphone for your device. (Course video
              calls are conducted with participants video-enabled.)
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Q: Do I need to be online at certain times for class?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Live 75-min video sessions with faculty and fellow students will
              be conducted throughout the course. Each week's live video call
              will be recorded and posted on the course platform.
            </p>
            <p>
              Video presentations, readings, discussions and learning activities
              will be accessible asynchronously and may be completed on
              participants' own schedule. Course resources and recordings will
              remain available up to 30 days after the close of the course.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            Q: What is Meridian's refund policy?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Full refunds are available until two (2) days before the course
              begins, by request via email.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            Q: Do I need to apply to Meridian graduate program to take this
            online course?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              You do not need to apply to a Meridian graduate degree program to
              take this online course. This course is one of Meridian’s{" "}
              <i>open-enrollment courses</i>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            Q: How can I reach course support?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              The course support team will be available to assist from start to
              finish. Please send your questions/requests/issues to{" "}
              <a href="mailto:openenrollment@meridianuniversity.edu">
                openenrollment@meridianuniversity.edu
              </a>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Q: Who will teach each session?</Accordion.Header>
          <Accordion.Body>
            <p>
              Convening faculty generally lead each live session. Contributing
              faculty typically contribute in one of the live course sessions.
              Course resources address the work of both convening and
              contributing faculty.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            Q: Is financial aid available for this public online course?
          </Accordion.Header>
          <Accordion.Body>
            <p>
              Meridian has institutional and federal financial aid options for
              our graduate degree programs. This open enrollment course does not
              have financial aid or payment plans available.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default onlineCoursesFAQ;
